<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    border
    :header-cell-style="{ background: '#fafafa', textAlign: 'center' }"
    :cell-style="{ textAlign: 'center' }"
  >
    <el-table-column prop="id" label="id"> </el-table-column>
    <el-table-column prop="headPortrait" label="头像">
      <template slot-scope="scope">
        <el-avatar
          v-if="scope.row.headPortrait"
          :size="60"
          :src="scope.row.headPortrait"
          @error="errorHandler"
        >
        </el-avatar> </template
    ></el-table-column>
    <el-table-column prop="studentName" label="学生姓名"> </el-table-column>
    <el-table-column prop="phone" label="手机号"> </el-table-column>
    <el-table-column prop="levelName" label="当前学生等级"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/deep/.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 12px;
  color: #000000d9 !important;
}
</style>
