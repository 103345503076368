<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.className"
                  placeholder="请输入班级名称"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.fullName"
                  placeholder="请输入班主任姓名"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入班主任电话"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.levelId"
                  clearable
                  placeholder="学生等级"
                >
                  <el-option
                    v-for="(item, index) in studentList"
                    :key="index"
                    :label="item.levelName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="studentNum" slot-scope="{ row }">
        <el-button type="text" @click="handNum(row)">{{
          row.studentNum
        }}</el-button>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
        <el-button type="text" size="mini" @click="jump(row)"
          >复习进度</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="userId">
          <el-select v-model="form.userId" clearable placeholder="选择班主任">
            <el-option
              v-for="(item, index) in teacherList"
              :key="index"
              :label="item.fullName"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
        <template slot="levelId">
          <el-select
            v-model="form.levelId"
            clearable
            placeholder="选择学生等级"
          >
            <el-option
              v-for="(item, index) in studentList"
              :key="index"
              :label="item.levelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      v-if="visibleTable"
      title=""
      :visible.sync="visibleTable"
      width="49%"
      :before-close="handleCloseTable"
      :close-on-click-modal="false"
    >
      <Table :tableData="studenNumList" />
      <div style="display: flex;justify-content: center;margin-top: 15px;">
        <el-button size="small" @click="recert">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { studentLevelList } from "@/api/user/level";
import { teacherList } from "@/api/user/teacher";
import {
  classClassPage,
  classAddClass,
  classClassView,
  classDeleteClass,
  classUpdateClass,
  classClassStudent,
  classReviewPage,
} from "@/api/user/class";
import dayjs from "dayjs";
import Table from "./components/table.vue";
import qs from "qs";
// import { MChannel, OChannel, EChannel } from "@/enums/beCurrent/EBeCurrent";
export default {
  name: "index",
  components: {
    Table,
  },
  data() {
    return {
      // MChannel: MChannel,
      // OChannel: OChannel,
      // EChannel: EChannel,
      searchData: {},
      page: {
        total: 0,
        currentPage: this.$route.query.newPage || 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "班级名称",
            prop: "className",
          },
          {
            label: "班主任姓名",
            prop: "fullName",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "学生等级",
            prop: "levelName",
          },
          {
            label: "学生数量",
            prop: "studentNum",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "班级名称",
            prop: "className",
            maxlength: 10,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入班级名称",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "选择班主任",
            prop: "userId",
            span: 16,
            row: true,
            placeholder: "选择班主任",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "选择学生等级",
            prop: "levelId",
            span: 16,
            row: true,
            placeholder: "选择学生等级",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      studentList: [], //学生等级下拉
      teacherList: [], //班主任下拉
      visibleTable: false, //密码弹窗
      studenNumList: [], //学生数量数组
    };
  },
  created() {
    this.getStudent();
    this.getTeacher();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      classClassPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {};
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //编辑
    async handleEdit(row) {
      this.form.id = row.id;
      classClassView(row.id).then((res) => {
        if (res.code == 200) {
          this.form = { ...res.data };
          this.dialogVisible = true;
        }
      });
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        classDeleteClass(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
        });
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form, "formformform");
          // return
          if (form.id) {
            classUpdateClass({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            classAddClass({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //关闭学生数量弹窗
    recert() {
      this.visibleTable = false;
    },
    //详情
    jump(row) {
      this.$router.push({
        path: "/user/review",
        query: {
          id: row.id,
          levelId: row.levelId,
          newPage: this.page.currentPage,
        },
      });
    },
    //获取学生等级下拉
    getStudent() {
      studentLevelList({}).then((res) => {
        if (res.code == 200) {
          this.studentList = res.data;
        }
      });
    },
    //获取班主任下拉
    getTeacher() {
      teacherList({}).then((res) => {
        if (res.code == 200) {
          this.teacherList = res.data;
        }
      });
    },
    // 打开班级学生弹窗
    handNum(row) {
      classClassStudent(row.id).then((res) => {
        if (res.code == 200) {
          this.studenNumList = res.data;
          this.visibleTable = true;
        }
      });
    },
    handleCloseTable() {
      this.visibleTable = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
